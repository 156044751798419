import { bigNumberConfig } from '@/utils/bigNumber';
import BigNumber from "bignumber.js";
import { addMinutes, differenceInMinutes, format, parse, startOfDay } from "date-fns";
import { Params } from "next/dist/shared/lib/router/utils/route-matcher";
import { ReadonlyURLSearchParams } from "next/navigation";
import { decimalAdjustWithBigNStr } from "./bigNumber";
import { assignInWith, isEmpty, partialRight } from 'lodash';
import { DEFAULT_MAX_POS_DIGITS } from '@/components/formFields/InputField/InputFiled.constants';

export const delay = (delayInms: number) => {
  return new Promise(resolve => setTimeout(resolve, delayInms));
}

/*
  replaceSubdomain is used for replacing subdomain in url
*/
export function replaceSubdomain(url: string, toSubdomain: string) {
  const replace = "://" + toSubdomain + ".";

  // Prepend http://
  if (!/^\w*:\/\//.test(url)) {
      url = "http://" + url;
  }

  // Check if we got a subdomain in url
  if (url.match(/\.\w*\b/g)?.length as number > 1) {
      return url.replace(/(:\/\/\w+\.)/, replace)
  }

  return url.replace(/:\/\/(\w*\.)/, `${replace}$1`)
}

/*
  replaceSubdomain is used for replacing [tenant] tag in url
*/
export function replaceTenant(path: string, tenantStr: string): string {

  const clonePath = path

  return clonePath.replace('[tenant]', `${tenantStr}`)
}

export const getPathWithParams = (params: Params, path: string): string => {
  return Object.entries(params).reduce((str, [key, val]) => {
    return str.replace(`[${key}]`, val as string)
  }, path)
}

/*
  isPathSelected is used for checking the path is selected or not
*/
export function isPathSelected(currentPath: string, relatedPaths: string[], params: Params): boolean {
  return !!relatedPaths.find(path => {
    const actPath = Object.entries(params).reduce((str, [key, value]) => {
      return str.replace(`[${key}]`, value)
    },path)
    return actPath === currentPath
  })
}

/*
  isPathSelected is used for validating authentication url lifetime
*/
export function isAuthLinkExipired(time?: number): boolean {
  if (!time) {
    return true
  }

  const lifetime = +(process?.env?.AUTH_LINK_LIFETIME || 30000)

  const expiredTime: number = (time + lifetime/1000)
  const currentTime: number = (new Date().valueOf())/1000

  return !!time && currentTime >= expiredTime
}

export const validateNumberInput = (number: number | string, dp: number = 2): boolean => {
  const _dp = Math.abs(Math.trunc(dp))
  const _number = number.toString()
  const dpRegStr = _dp <= 0 ? "" : `\\.{0,1}(?:[0-9]{0,${Math.max(0, _dp)}})?`
  const regex = new RegExp(`^-{0,1}[0-9]+${dpRegStr}$`)
  return regex.test(_number)
}

export const validatePercentageInput = (number: number | string, dp: number = 2): boolean => {
  const _dp = Math.abs(Math.trunc(dp))
  const _number = number.toString()
  const dpRegStr = _dp <= 0 ? "" : `(\\.+[0-9]{0,${Math.max(0, _dp)}})?`
  const regex = new RegExp(`^(?:[0-9][0-9]?${dpRegStr}|100)$`)
  return regex.test(_number)
}

export const dpProtection = (value: string, maxDp: number) => {
  if (typeof value !== 'string' || !Number.isInteger(maxDp)) { return value?.toString() }

  if (maxDp < 0) { return value?.toString() }
  const regExp = new RegExp(`^(-?[0-9]*)(\.[0-9]{0,${Math.max(maxDp, 0)}})?.*$`, "g")

  return value?.toString()?.replace(regExp, (_match, integerPart, decimalPart) => {
    return (integerPart || "") + (decimalPart || "")
  })
};

export const mergeDeep = <TObject extends Record<string | number | symbol, any>, TSource extends Record<string | number | symbol, any>>(target: TObject, source: TSource): TObject & TSource | TObject | TSource => {
  const isObject = (obj: any): obj is Record<string | number | symbol, any> =>
    obj && typeof obj === 'object';

  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach((key: keyof TObject) => {
    const targetValue = target[key as keyof TObject];
    const sourceValue = source[key as keyof TSource];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = mergeDeep(Object.assign({}, targetValue), sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}

export const decimalAdjust = (inputVal: number, type: 'trunc' | 'round' | 'floor' | 'ceil' = 'trunc', exp = -2, dpPrecision = (Math.abs(exp)) + 2): string => {
  try {
    let _exp = -(Math.abs(exp))
    // If the exp is undefined or zero...
    if (typeof _exp === 'undefined' || +_exp === 0) {
      return Math[type](inputVal).toString();
    }
    const numLen = Math.ceil(Math.log10(Math.abs(+inputVal) + 1));
    let value: string | string[] | number = inputVal.toString()
    value = (+value).toPrecision(Math.min(numLen + dpPrecision, 100));
    _exp = +_exp;
    // If the value is not a number or the _exp is not an integer...
    if (isNaN(inputVal) || !(typeof _exp === 'number' && _exp % 1 === 0)) {
      return NaN.toString();
    }
    // Shift
    value = value.toString().split('e');
    value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - _exp) : -_exp)));
    // Shift back
    value = value.toString().split('e');
    return toFixed((+(value[0] + 'e' + (value[1] ? (+value[1] + _exp) : _exp))), -_exp);
  } catch (error) {
    console.error('decimalAdjust error:', error);
    return NaN.toString();
  }
}

export const toFixed = (x: number, exp = 2): string => {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split('e-')[1]);
    if (e) {
        x *= Math.pow(10,e-1);
        return '0.' + (new Array(e)).join('0') + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split('+')[1]);
    if (e > 20) {
        e -= 20;
        x /= Math.pow(10,e);
        return x + (new Array(e+1)).join('0');
    }
  }

  return x.toFixed(exp)
}

export const numberToCurrency = (num: number | string | BigNumber, dp: number = 2, currency :string = '$ '): string => {
  if (num.constructor.prototype._isBigNumber) {
    return decimalAdjustWithBigNStr(num, {
      ...bigNumberConfig.FORMAT,
      prefix: currency,
      groupSeparator: ',',
    })
  }

  return decimalAdjustWithBigNStr(BigNumber(num), dp, {
    ...bigNumberConfig.FORMAT,
    prefix: currency,
    groupSeparator: ',',
  })
}

// Check type of value
export const isType = <T>(value: unknown, Cls: new (...args: any[]) => T): value is T => {
  // Intentional use of loose comparison operator detects `null` and `undefined`, and nothing else!
  return value != null && Object.getPrototypeOf(value).constructor === Cls;
};

export const ExtractNameFromEmail = (email?: string): string => (email ? email.replace(/@.*$/, '') : '');

export const ObjectEntries = <TKey extends string, TValue extends any>(obj: Record<TKey, TValue>): [TKey, TValue][] => {
  return Object.entries(obj) as [TKey, TValue][]
}

export const generateUUID = () => { // Public Domain/MIT
  var d = new Date().getTime();//Timestamp
  var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if(d > 0){//Use timestamp until depleted
          r = (d + r)%16 | 0;
          d = Math.floor(d/16);
      } else {//Use microseconds since page-load if supported
          r = (d2 + r)%16 | 0;
          d2 = Math.floor(d2/16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
}

export const downloadFileWithURL = (document: Document, url: string, filename?: string): void => {
  const downloadLink = document.createElement('a')
  downloadLink.href = url
  if (filename) {
    downloadLink.download = filename
  }
  document.body.appendChild(downloadLink)
  downloadLink.dispatchEvent(
    new MouseEvent('click', { 
      bubbles: true, 
      cancelable: true, 
      view: window 
    })
  );
  document.body.removeChild(downloadLink)

  // revoke the generated URl
  URL.revokeObjectURL(url);
}

export function* previewPDF (window: Window): Generator<Window | null, any, string> {
  const win = window.open('', '_blank');
  let url = ''
  if (win != null) {
    win.location.href = '/others/pdf_loading';
    win.document.title = 'receipt_template';
    win.focus();
  }
  url = yield win
  if (win != null) {
    win.location.href = url;
    win.focus();
  }
  // revoke the generated URl
  URL.revokeObjectURL(url);
}

export const searchParamsToObj = <T = Record<string, any>>(searchParams: URLSearchParams | ReadonlyURLSearchParams): T => {
  return Object.fromEntries(searchParams.entries()) as unknown as T
}

export const toURLSearchParams = (paramsObj:  Record<string, any>): URLSearchParams => {
  const params = new URLSearchParams();
  Object.keys(paramsObj).forEach((key) => {
    const value = paramsObj[key];
    if (value !== undefined && value !== null) {
      params.append(key, String(value));
    }
  });
  return params;
}

export const getFormData = async <T = Record<string, any>>(req: Request): Promise<T> => {
  return (await req.json()) as unknown as T
}

export const getOffsetFromDateTime = (date: Date | string | undefined | null, originFormatStr: string = "yyyy/MM/dd HH:mm:ss") => {
  if (!date) {
    return 0;
  }

  const parsedDate = typeof date === 'string' ? parse(date, originFormatStr, new Date()) : date;
  const startOfDate = startOfDay(parsedDate)

  return differenceInMinutes(parsedDate, startOfDate)
}

export const getDateTimeFromOffset = (offset: string | number | null | undefined, dateRef: Date = new Date()) => {
  if (offset === 0) return startOfDay(dateRef)

  if (!offset) {
    return '';
  }

  const startOfDate = startOfDay(dateRef)
  const result = addMinutes(startOfDate, +offset)

  return result
}

export const getTimeStrFromOffset = (offset: string | number | null | undefined, dateRef: Date = new Date(), formatStr: string = 'HH:mm') => {
  if (offset === 0) return format(startOfDay(dateRef), formatStr)

  if (!offset) {
    return '';
  }

  const startOfDate = startOfDay(dateRef)
  const result = addMinutes(startOfDate, +offset)

  return format(result, formatStr)
}

export const getOffsetFromOffsetStr = (str: string, dateRef: Date = new Date(), formatStr: string = 'HH:mm') => {
  const parsedDate = parse(str, formatStr, dateRef)
  const startOfDate = startOfDay(parsedDate)

  return differenceInMinutes(parsedDate, startOfDate)
}

export const assignAndKeepExist = (() => {
  function customizer(objValue: any, srcValue: any) {
    let val = objValue

    if (isEmpty(objValue)) {
      val = srcValue
    }

    if (isEmpty(srcValue)) {
      val = objValue
    }

    return val;
  }

  return partialRight(assignInWith, customizer)
})()

export const checkMaxPosDigits = (newValue: string = '', maxPosDigits: number = DEFAULT_MAX_POS_DIGITS, oldValue: string = '') => {
  const posPart = newValue.replace(/^(\d*)(\.?\d*)$/, '$1')
  const negPart = newValue.replace(/^\d*(\.?\d*)$/, '$1')
  const oldPosValue = oldValue.toString().replace(/^(\d*)(\.?\d*)$/, '$1')
  if (maxPosDigits && posPart.length > maxPosDigits) {
    return {
      passed: false,
      posPart,
      negPart,
      oldPosValue
    }
  }

  return {
    passed: true,
    posPart,
    negPart,
    oldPosValue
  }
}

export const parseToMinimumIntegerDigit = (myNumber: number) => {
  return myNumber.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  })
}

export const formatNumberOrder = (num: number) => {
  if (num % 100 > 10 && num % 100 < 20) return `${num}th`

  switch (num % 10) {
    case 1:
      return `${num}st`
    case 2:
      return `${num}nd`
    case 3:
      return `${num}rd`
    default:
      return `${num}th`
  }
}

export const roundup = (x: number) => {
  return x % 5 ? x - (x % 5) + 5 : x;
};

export const camelToKebab = (str: string): string => {
  return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
};

export const camelToSnake = (str: string): string => {
  return str.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
};

export const kebabToCamel = (str: string): string => {
  return str.replace(/-./g, (match) => match[1].toUpperCase());
};

export const snakeToCamel = (str: string): string => {
  return str.replace(/_./g, (match) => match[1].toUpperCase());
};

export const pascalToKebab = (str: string): string => {
  return camelToKebab(str.charAt(0).toLowerCase() + str.slice(1));
};

export const pascalToSnake = (str: string): string => {
  return camelToSnake(str.charAt(0).toLowerCase() + str.slice(1));
};

export const kebabToSnake = (str: string): string => {
  return str.replace(/-/g, '_');
};

export const snakeToKebab = (str: string): string => {
  return str.replace(/_/g, '-');
};

export const kebabToPascal = (str: string): string => {
  return str.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('');
};

export const snakeToPascal = (str: string): string => {
  return str.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('');
};

export const camelToPascal = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const pascalToCamel = (str: string): string => {
  return str.charAt(0).toLowerCase() + str.slice(1);
};

export const convertKeys = <T extends Record<string, any>>(obj: T, converter: (key: string) => string): Record<string, any> => {
  return Object.keys(obj).reduce((acc, key) => {
    const newKey = converter(key);
    acc[newKey] = obj[key]; // Preserve the value
    return acc;
  }, {} as Record<string, any>);
}
