import BigNumber from "bignumber.js";

export const maximumDpOnRails = 16

export const decimalPlaceForTaxConstant = 50

export const bigNumberConfig: WithRequiredProperty<BigNumber.Config, 'DECIMAL_PLACES'> = {
  DECIMAL_PLACES: 3,
  EXPONENTIAL_AT: 1e+9,
  POW_PRECISION: 50,
  RANGE: 1e+9,
  ROUNDING_MODE: 1,
  MODULO_MODE: 1,
  FORMAT: {
    // string to prepend
    prefix: '',
    // decimal separator
    decimalSeparator: '.',
    // grouping separator of the integer part
    groupSeparator: '',
    // primary grouping size of the integer part
    groupSize: 3,
    // secondary grouping size of the integer part
    secondaryGroupSize: 0,
    // grouping separator of the fraction part
    fractionGroupSeparator: ' ',
    // grouping size of the fraction part
    fractionGroupSize: 0,
    // string to append
    suffix: ''
  }
}

BigNumber.config(bigNumberConfig)

export const decimalAdjustWithBigN = (inputVal: number | string | BigNumber = 0, ...rest: OverloadParameters<typeof BigNumber.prototype.decimalPlaces>): BigNumber => {
  try {
    let _bigNumber: typeof BigNumber = BigNumber
    let inputIsBigNum = false
    if (inputVal.constructor.prototype._isBigNumber) {
      _bigNumber = (inputVal.constructor as typeof BigNumber)
      inputIsBigNum = true
    }
    const DECIMAL_PLACES = _bigNumber.config().DECIMAL_PLACES || bigNumberConfig.DECIMAL_PLACES
    const ROUNDING_MODE = _bigNumber.config().ROUNDING_MODE || bigNumberConfig.ROUNDING_MODE

    if (rest.length === 0) {
      return (!inputIsBigNum ? _bigNumber(inputVal) : inputVal as BigNumber).decimalPlaces(DECIMAL_PLACES, ROUNDING_MODE)
    }

    return ((!inputIsBigNum ? _bigNumber(inputVal) : inputVal as BigNumber).decimalPlaces as (...rest: any[]) => BigNumber)(rest[0], ROUNDING_MODE);
  } catch (error) {
    console.error("🚀 ~ decimalAdjustWithBigN ~ error:", error)
    return BigNumber(inputVal)
  }
}


export const decimalAdjustWithBigNStr = (inputVal: number | string | BigNumber, ...rest: OverloadParameters<typeof BigNumber.prototype.toFormat>): string => {
  try {
    let rs = decimalAdjustWithBigN(inputVal)
    const _bigNumber = (rs.constructor as typeof BigNumber)
    const DECIMAL_PLACES = _bigNumber.config().DECIMAL_PLACES || bigNumberConfig.DECIMAL_PLACES
    const ROUNDING_MODE = _bigNumber.config().ROUNDING_MODE || bigNumberConfig.ROUNDING_MODE
    const FORMAT = _bigNumber.config().FORMAT || {}

    let newArg = rest.map(item => {
      if (typeof item === 'object') {
        return {
          ...FORMAT,
          ...item,
        }
      }
      return item
    })

    if (newArg.length === 0 || typeof newArg[0] === 'object') {
      newArg = [DECIMAL_PLACES, ROUNDING_MODE, ...newArg]
    }

    return (rs.toFormat as (...rest: any[]) => string)(...newArg);
  } catch (error) {
    return inputVal.toString()
  }
}

const qtyBigNumber = BigNumber.clone(bigNumberConfig)
const priceBigNumber = BigNumber.clone(bigNumberConfig)
const percentageBigNumber = BigNumber.clone({
  ...bigNumberConfig,
  DECIMAL_PLACES: bigNumberConfig.DECIMAL_PLACES * 2,
})

export const initBigNumConfig = ({
  quantityConfig,
  priceConfig
}: {
  quantityConfig?: BigNumber.Config
  priceConfig?: BigNumber.Config
}) => {
  qtyBigNumber.config({
    ...bigNumberConfig,
    ...quantityConfig,
  })
  priceBigNumber.config({
    ...bigNumberConfig,
    ...priceConfig,
  })
}

export {
  qtyBigNumber,
  priceBigNumber,
  percentageBigNumber
}

